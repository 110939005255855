import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import Select from '../../../../inputs/Dropdown';
import UviItem from './UviItem';
import SubletItem from './SubletItem';
import UviCustomItem from './UviCustomItem';
import Estimates from './UviEstimates';

import AllInputs from '../../../../inputs/AllInputs';
import FileUploadButton from '../../../../inputs/FileUploadButton';
import {
	addWorkItem,
	addUviItem,
} from '../../../../actions/apiWorkItems';
import {
	addVehicleProperty,
	addVehiclePropertyRaw,
} from '../../../../actions/AllUpdates';
import {
	addVehicleNoteRaw,
} from '../../../../actions/apiVehicleNotes';
import {
	processDepartments,
} from '../../../../actions/apiGetInfo';
import {
	vsearch,
	lookupIsAdmin,
} from '../../../../actions/lookups';
import {
	userStamp,
} from '../../../../utils/dateFunctions';
import {
	addPartOrderRaw,
} from '../../../../actions/apiPartOrders';

class UviForm extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			addEstimateError: '', timer: 0, detailvalue: '', detail_parts_number: '', detail_qty: '', detail_parts: 0, detail_labor_hours: 0, detail_labor: 0, detail_total: 0, sublet_subtotal: 0, tires_qty: 0, tires_parts: 0, tires_labor: 0, tires_labor_hours: 0, tires_total: 0, uvi_approved_total: 0, tires_part_number: 0, tires_active: true, decline_all: false,
		};
		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onClickCustom = this.onClickCustom.bind(this);
		this.vehicleProperty = this.vehicleProperty.bind(this);
		this.onPrint = this.onPrint.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.updateTotals = this.updateTotals.bind(this);
		this.onChangeTires = this.onChangeTires.bind(this);
		this.onClickSublet = this.onClickSublet.bind(this);
		this.onBlurTotal = this.onBlurTotal.bind(this);
		this.onLock = this.onLock.bind(this);
		this.onUnLock = this.onUnLock.bind(this);
		this.onEstimateComplete = this.onEstimateComplete.bind(this);
		this.onDeclineAll = this.onDeclineAll.bind(this);
		this.onDecline = this.onDecline.bind(this);
	}

	onLock(e) {
		const uid = this.props.current_user.user;
		const user_name = this.props.current_user.user_name || this.props.current_user.email;

		const data = {
			vehicle_id: this.props.active_vehicle.id,
			key: 'uvi status',
			value: 'completed',
			uid,
			user_name,
		};

		var data2 = {
			vehicle_id: this.props.active_vehicle.id,
			value: `UVI Form Approved: ${this.state.uvi_approved_total}`,
			uid,
			user_name,
		};

		const errorType = 'uviform';
		Promise.all([this.props.addVehicleProperty(data, errorType), this.props.addVehicleNoteRaw(data2, 'addVehicleNoteError')]).then(() => {
			this.props.activeVehicle(this.props.active_vehicle.id);
		});
	}

	onDeclineAll(e) {
		const uid = this.props.current_user.user;
		const user_name = this.props.current_user.user_name || this.props.current_user.email;
		const total = document.getElementById(`other_total_${this.props.active_vehicle.id}`).innerHTML;
		this.setState({ decline_all: true });

		const data = {
			vehicle_id: this.props.active_vehicle.id,
			key: 'uvi status',
			value: 'completed',
			uid,
			user_name,
		};

		const datadecline = {
			vehicle_id: this.props.active_vehicle.id,
			key: 'decline all',
			value: 'true',
			uid,
			user_name,
		};

		const data3 = {
			vehicle_id: this.props.active_vehicle.id,
			key: 'uvi notes',
			value: '----------------------DECLINED ALL-----------------',
			uid,
			user_name,
		};

		var data2 = {
			vehicle_id: this.props.active_vehicle.id,
			value: 'UVI Form Declined all',
			uid,
			user_name,
		};

		const errorType = 'uviform';
		Promise.all([this.props.addVehicleProperty(data, errorType), this.props.addVehicleProperty(data, 'addVehicleProperty'), this.props.addVehicleProperty(datadecline, 'addVehicleProperty'), this.props.addVehicleProperty(data3, 'addVehicleProperty'), this.props.addVehicleNoteRaw(data2, 'addVehicleNoteError')]).then(() => {
			this.props.activeVehicle(this.props.active_vehicle.id);
		});
	}

	onEstimateComplete(e) {
		const uid = this.props.current_user.user;
		const user_name = this.props.current_user.user_name || this.props.current_user.email;
		const total = document.getElementById(`other_total_${this.props.active_vehicle.id}`).innerHTML;

		const data = {
			vehicle_id: this.props.active_vehicle.id,
			key: 'uvi status',
			value: 'waiting',
			uid,
			user_name,
		};

		var data2 = {
			vehicle_id: this.props.active_vehicle.id,
			value: `UVI Form estimate of ${total} submitted`,
			uid,
			user_name,
		};
		const errorType = 'uviform';
		Promise.all([this.props.addVehicleProperty(data, errorType), this.props.addVehicleNoteRaw(data2, 'addVehicleNoteError')]).then(() => {
			this.props.activeVehicle(this.props.active_vehicle.id);
		});
	}

	onUnLock(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			key: 'uvi status',
			value: 'waiting',
		};

		var data2 = {
			vehicle_id: this.props.active_vehicle.id,
			value: 'UVI Form UnLocked',
			uid: this.props.current_user.user,
		};

		const errorType = 'uviform';
		Promise.all([this.props.addVehicleProperty(data, errorType), this.props.addVehicleNoteRaw(data2, 'addVehicleNoteError')]).then(() => {
			this.props.activeVehicle(this.props.active_vehicle.id);
		});
	}

	componentDidMount() {
		const vehicle = this.props.active_vehicle;

		const detailvalue = this.vehicleProperty('uvi detail package');
		const detail_parts_number = this.vehicleProperty('uvi detail part number');
		const detail_qty = this.vehicleProperty('uvi detail qty');
		const detail_parts = this.vehicleProperty('uvi detail parts');
		const detail_labor_hours = this.vehicleProperty('uvi detail labor hours');
		const detail_labor = this.vehicleProperty('uvi detail labor');
		const detail_total = this.vehicleProperty('uvi detail total');

		const tires_part_number = this.vehicleProperty('uvi tires part number');
		const tires_qty = this.vehicleProperty('uvi tires qty');
		const tires_parts = this.vehicleProperty('uvi tires parts');
		const tires_labor_hours = this.vehicleProperty('uvi tires labor hours');
		const tires_labor = this.vehicleProperty('uvi tires labor');
		const tires_total = this.vehicleProperty('uvi tires total');
		const tires_active = this.vehicleProperty('uvi tires active') !== 'false';
		const uvi_approved_total = this.vehicleProperty('uvi_approved_total');
		const decline_all = this.vehicleProperty('decline all') === 'true';

		this.setState({
			detailvalue,
			detail_parts_number,
			detail_qty,
			detail_parts,
			detail_parts_number,
			detail_labor_hours,
			detail_labor,
			detail_total,
			tires_labor_hours,
			tires_part_number,
			tires_qty,
			tires_total,
			tires_labor,
			tires_parts,
			tires_active,
			decline_all,
			uvi_approved_total,
		});

		const timer = setInterval(() => {
			this.updateTotals();
		}, 5000);

		this.setState({ timer });
	}

	componentWillUnmount() {
		clearInterval(this.state.timer);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeTires(e) {
		let qty = parseInt(this.state.tires_qty);
		let parts = parseFloat(this.state.tires_parts);

		if (e.target.name === 'tires_qty') {
			qty = parseInt(e.target.value);
		} else if (e.target.name === 'tires_parts') {
			parts = parseFloat(e.target.value);
		}

		const labor_rate = 1.3;
		const labor_per_tire = 0.325;
		const labor = parseFloat(21.875 * qty).toFixed(2);
		const total = (Number(labor) + Number(parts)).toFixed(2);
		const labor_hours = (0.325 * qty).toFixed(3);
		this.setState({
			tires_qty: e.target.value, tires_parts: parts, tires_labor: labor, tires_labor_hours: labor_hours, tires_total: total, tires_qty: qty,
		});
		const errorType = 'addVehicleProperty';

		var self = this;
		const vehicle_id = this.props.active_vehicle.id;
		const vehicle = this.props.active_vehicle;
		const uid = this.props.current_user.user;
		const user_name = this.props.current_user.user_name || this.props.current_user.email;

		const data2 = {
			vehicle_id,
			key: 'uvi tires qty',
			value: qty,
			uid,
			user_name,
		};
		const data3 = {
			vehicle_id,
			key: 'uvi tires parts',
			value: parts,
			uid,
			user_name,
		};
		const data4 = {
			vehicle_id,
			key: 'uvi tires labor hours',
			value: labor_hours,
			uid,
			user_name,
		};
		const data5 = {
			vehicle_id,
			key: 'uvi tires labor',
			value: labor,
			uid,
			user_name,
		};
		const data6 = {
			vehicle_id,
			key: 'uvi tires total',
			value: total,
			uid,
			user_name,
		};
		const data7 = {
			vehicle_id,
			key: 'uvi tires active',
			value: this.state.tires_active,
			uid,
			user_name,
		};

		Promise.all([
			self.props.addVehiclePropertyRaw(data2, errorType),
			self.props.addVehiclePropertyRaw(data3, errorType),
			self.props.addVehiclePropertyRaw(data4, errorType),
			self.props.addVehiclePropertyRaw(data5, errorType),
			self.props.addVehiclePropertyRaw(data6, errorType),
			self.props.addVehiclePropertyRaw(data7, errorType),
		]).then(() => {
			self.props.activeVehicle(vehicle_id);
		});
	}

	onDecline(e) {
		const self = this;
		const vehicle_id = this.props.active_vehicle.id;
		const vehicle = this.props.active_vehicle;
		const uid = this.props.current_user.user;
		const user_name = this.props.current_user.user_name || this.props.current_user.email;
		this.setState({ tires_active: false });
		const data = {
			vehicle_id,
			key: 'uvi tires active',
			value: 'false',
			uid,
			user_name,
		};

		Promise.all([
			self.props.addVehicleProperty(data, 'addVehicleProperty'),
		]).then(() => {
			self.props.activeVehicle(vehicle_id);
		});
	}

	onBlur(e) {
		const self = this;
		const vehicle_id = this.props.active_vehicle.id;
		const vehicle = this.props.active_vehicle;
		const uid = this.props.current_user.user;
		const user_name = this.props.current_user.user_name || this.props.current_user.email;
		this.setState({ tires_part_number: e.target.value });
		const data = {
			vehicle_id,
			key: 'uvi tires part number',
			value: e.target.value,
			uid,
			user_name,
		};

		Promise.all([
			self.props.addVehicleProperty(data, 'addVehicleProperty'),
		]).then(() => {
			self.props.activeVehicle(vehicle_id);
		});
	}

	onBlurTotal(e) {
		const self = this;
		const vehicle_id = this.props.active_vehicle.id;
		const vehicle = this.props.active_vehicle;
		const uid = this.props.current_user.user;
		const user_name = this.props.current_user.user_name || this.props.current_user.email;
		this.setState({ uvi_approved_total: e.target.value });
		const data = {
			vehicle_id,
			key: 'uvi_approved_total',
			value: e.target.value,
			uid,
			user_name,
		};

		Promise.all([
			self.props.addVehicleProperty(data, 'addVehicleProperty'),
		]).then(() => {
			self.props.activeVehicle(vehicle_id);
		});
	}

	updateTotals() {
		const vehicle = this.props.active_vehicle;

		let primary_total_all = 0;
		let x = document.getElementsByClassName(`total_${vehicle.id}`);
		let i;
		for (i = 0; i < x.length; i++) {
			primary_total_all += parseFloat(x[i].dataset.total) || 0;
		}
		const si = document.getElementById(`si_${vehicle.id}`).dataset.total;
		const y = document.getElementById(`primary_total_${vehicle.id}`);

		let parts_total = 0;
		x = document.getElementsByClassName(`parts_${vehicle.id}`);
		i = 0;
		for (i = 0; i < x.length; i++) {
			parts_total += parseFloat(x[i].dataset.total) || 0;
		}
		document.getElementById(`parts_total_${vehicle.id}`).innerHTML = parseFloat(parts_total).toFixed(2);

		let labor_total = 0;
		x = document.getElementsByClassName(`labor_${vehicle.id}`);
		i = 0;
		for (i = 0; i < x.length; i++) {
			labor_total += parseFloat(x[i].dataset.total) || 0;
		}

		var shop_supplies = (labor_total * 0.1075);
		var shop_supplies_total = 0;
		if (shop_supplies <= 57.5) {
			shop_supplies_total = shop_supplies;
		} else {
			shop_supplies_total = 57.5;
		}
		document.getElementById(`shop_supplies_total_${vehicle.id}`).innerHTML = parseFloat(shop_supplies_total).toFixed(2);

		document.getElementById(`labor_total_${vehicle.id}`).innerHTML = parseFloat(labor_total).toFixed(2);

		let total = 0;
		x = document.getElementsByClassName(`labor_hours_${vehicle.id}`);
		i = 0;
		for (i = 0; i < x.length; i++) {
			total += parseFloat(x[i].dataset.total) || 0;
		}
		document.getElementById(`labor_hours_total_${vehicle.id}`).innerHTML = parseFloat(total).toFixed(2);

		let sub_total = 0;
		x = document.getElementsByClassName(`sublet_${vehicle.id}`);
		i = 0;
		for (i = 0; i < x.length; i++) {
			sub_total += parseFloat(x[i].dataset.total) || 0;
		}
		var cost_markup = sub_total * 0.3;
		if (cost_markup <= 300) {
			sub_total += cost_markup;
		} else {
			sub_total += 300;
		}

		document.getElementById(`sublet_total_${vehicle.id}`).innerHTML = parseFloat(sub_total).toFixed(2);
		document.getElementById(`sublet_subtotal_${vehicle.id}`).innerHTML = parseFloat(sub_total).toFixed(2);
		const newtotal = parseFloat(shop_supplies_total) + parseFloat(labor_total) + parseFloat(parts_total);

		if (y) {
			y.innerHTML = parseFloat(newtotal).toFixed(2);
		}
		document.getElementById(`other_total_${vehicle.id}`).innerHTML = (parseFloat(sub_total) + parseFloat(newtotal)).toFixed(2);
	}

	onChangeSelect(e, part_number, qty, parts, labor_hours, labor, total) {
		var self = this;
		const vehicle_id = this.props.active_vehicle.id;
		const vehicle = this.props.active_vehicle;
		const errorType = 'updateVehicleError';
		const key = 'uvi detail package';
		const value = e;
		const uid = this.props.current_user.user;
		const user_name = this.props.current_user.user_name || this.props.current_user.email;
		const description = `${value} - Kit`;
		this.setState({
			detail_parts_number: part_number, detailvalue: value, detail_qty: qty, detail_labor_hours: labor_hours, detail_labor: labor, detail_total: total, detail_parts: parts,
		});

		const data = {
			vehicle_id,
			key,
			value,
			uid,
			user_name,
		};
		const data1 = {
			vehicle_id,
			key: 'uvi detail part number',
			value: part_number,
			uid,
			user_name,
		};
		const data2 = {
			vehicle_id,
			key: 'uvi detail qty',
			value: qty,
			uid,
			user_name,
		};
		const data3 = {
			vehicle_id,
			key: 'uvi detail parts',
			value: parts,
			uid,
			user_name,
		};
		const data4 = {
			vehicle_id,
			key: 'uvi detail labor hours',
			value: labor_hours,
			uid,
			user_name,
		};
		const data5 = {
			vehicle_id,
			key: 'uvi detail labor',
			value: labor,
			uid,
			user_name,
		};
		const data6 = {
			vehicle_id,
			key: 'uvi detail total',
			value: total,
			uid,
			user_name,
		};

		const data_parts = {
			vehicle_id,
			part_ordered: description,
		};
		this.props.addPartOrderRaw(data_parts, 'addPartOrderError');

		Promise.all([
			self.props.addVehiclePropertyRaw(data, errorType),
			self.props.addVehiclePropertyRaw(data1, errorType),
			self.props.addVehiclePropertyRaw(data2, errorType),
			self.props.addVehiclePropertyRaw(data3, errorType),
			self.props.addVehiclePropertyRaw(data4, errorType),
			self.props.addVehiclePropertyRaw(data5, errorType),
			self.props.addVehiclePropertyRaw(data6, errorType),
		]).then(() => {
			self.props.activeVehicle(vehicle_id);
		});
	}

	vehicleProperty(name) {
		const self = this;

		const value = _.filter(self.props.vehicle.vehicle_properties, o => o.key === name);

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	detailOptions() {
		const self = this;
		const select_options = [
			{
				name: 'UVI Full-Gas', part_number: '', id: 1, qty: 1, parts: '238.98', labor_hours: '2', labor: '242.50', total: '481.48',
			},
			{
				name: 'UVI Full-Diesel', part_number: '', id: 2, qty: 1, parts: '416.05', labor_hours: '3.5', labor: '422.50', total: '838.55',
			},
			{
				name: 'UVI Full-Hybrid', part_number: '', id: 3, qty: 1, parts: '238.98', labor_hours: '2', labor: '242.50', total: '481.48',
			},
			{
				name: 'SSG - Safety, LOF, Air Filter, Rotate Tires', part_number: '', id: 4, qty: 1, parts: '106.82', labor_hours: '1.4', labor: '169.50', total: '276.32',
			},
			{
				name: 'Choose Item', part_number: '', id: 5, qty: 0, parts: 0, labor_hours: 0, labor: 0, total: 0,
			},
			{
				name: 'CPO Inspection', part_number: '', id: 6, qty: 1, parts: '273.98', labor_hours: '2.3', labor: '327.50', total: '601.48',
			}];

		const first_options = select_options.filter((option) => {
			let selected = false;
			const dataid = 0;
			if (self.state.detailvalue === option.name) {
				selected = true;
			}

			var obj = {};

			if (option.name === 'SSG - Safety, LOF, Air Filter, Rotate Tires') {
				if (self.state.detailvalue === 'SSG - Safety, LOF, Air Filter, Rotate Tires') {
					return true;
				}
				return false;
			}
			return true;
		});

		const options = first_options.map((option) => {
			let selected = false;
			const dataid = 0;
			if (this.state.detailvalue === option.name) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.name, value: option.name, selected, parts: option.parts, qty: option.qty, labor_hours: option.labor_hours, labor: option.labor, total: option.total, part_number: option.part_number,
			};
			return obj;
		});
		return options || [];
	}

	tireOptions() {
		const select_options = [
			{
				name: 'Front Brakes', part_number: '', id: 1, qty: 1, parts: '67.71', labor_hours: '1.5', labor: '137.29', total: '205.00',
			},
			{
				name: 'Rear Brakes', part_number: '', id: 2, qty: 1, parts: '75.14', labor_hours: '1.5', labor: '129.86', total: '205.00',
			},
			{
				name: 'Rear Brakes - Super Duty', part_number: '', id: 3, qty: 1, parts: '75.14', labor_hours: '3', labor: '275.86', total: '351.00',
			},
			{
				name: 'Rotate balance', part_number: '', id: 4, qty: 1, parts: '', labor_hours: '.8', labor: '56.95', total: '56.95',
			},
			{
				name: 'Mount balance', part_number: '', id: 5, qty: 1, parts: '', labor_hours: '1.3', labor: '87.50', total: '87.50',
			},
			{
				name: 'Alignment', part_number: '99', id: 6, qty: 1, parts: '', labor_hours: '1.2', labor: '99.00', total: '99.00',
			},
			{
				name: 'Battery-Interstate', part_number: '', id: 7, qty: 1, parts: '', labor_hours: '', labor: '', total: '',
			},
			{
				name: 'Choose Item', part_number: '', id: 8, qty: 0, parts: 0, labor_hours: 0, labor: 0, total: 0,
			},
			{
				name: 'CAF - (Cabin Air Filter)', part_number: '', id: 9, qty: 1, parts: 25, labor_hours: '.3', labor: '25.00', total: '50.00',
			}];

		const options = select_options.map((option) => {
			let selected = false;
			const dataid = 0;
			if (this.vehicleProperty('uvi detail package') === option.name) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.name, value: option.name, selected, parts: option.parts, qty: option.qty, labor_hours: option.labor_hours, labor: option.labor, total: option.total, part_number: option.part_number,
			};
			return obj;
		});
		return options || [];
	}

	onClick(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			ro_status: 'getapproved',
			item_type: 'set-locked',
			active: true,
		};

		Promise.all([
			this.props.addUviItem(data, 'addEstimateError'),
		]).then(() => {
			this.props.activeVehicle(this.props.active_vehicle.id);
		});
	}

	onClickCustom(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			ro_status: 'getapproved',
			item_type: 'custom',
			active: true,
		};

		Promise.all([
			this.props.addUviItem(data, 'addEstimateError'),
		]).then(() => {
			this.props.activeVehicle(this.props.active_vehicle.id);
		});
	}

	onClickSublet(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			ro_status: 'getapproved',
			item_type: 'sublet',
			active: true,
		};

		Promise.all([
			this.props.addUviItem(data, 'addEstimateError'),
		]).then(() => {
			this.props.activeVehicle(this.props.active_vehicle.id);
		});
	}

	onPrint(e) {
		const vehicle = this.props.active_vehicle;
		const print_page = document.getElementById(`uvi_form_${vehicle.id}`);
		print_page.classList.remove('strikeout');
		html2canvas(print_page)
			.then((canvas) => {
				const pdf = new jsPDF('p', 'pt', 'letter');
				for (var i = 0; i <= print_page.clientHeight / 980; i++) {
		                var srcImg = canvas;
		                var sX = 0;
		                var sY = 980 * i;
		                var sWidth = 900;
		                var sHeight = 980;
		                var dX = 0;
		                var dY = 0;
		                var dWidth = 900;
		                var dHeight = 980;

		                window.onePageCanvas = document.createElement('canvas');
		                onePageCanvas.setAttribute('width', 900);
		                onePageCanvas.setAttribute('height', 980);
		                var ctx = onePageCanvas.getContext('2d');
		                ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

		                var canvasDataURL = onePageCanvas.toDataURL('image/png', 1.0);

		                var width = onePageCanvas.width;
		                var height = onePageCanvas.clientHeight;

		                if (i > 0) {
		                    pdf.addPage();
		                }

		                pdf.setPage(i + 1);

		                pdf.addImage(canvasDataURL, 'PNG', 10, 20, (width * 0.72), (height * 0.72));
		            }
				    pdf.save(`uvi_form_${vehicle.id}.pdf`);
			})
			.catch((error) => {
				console.log('oops, something went wrong!', error);
			});
	}

	onPrint2(e) {
		const vehicle = this.props.active_vehicle;
		const print_page = document.getElementById(`uvi_form_${vehicle.id}`);

		domtoimage.toPng(print_page)
			.then((canvasOrDataUrl) => {
				if (typeof canvasOrDataUrl == 'string') {
					const pdf = new jsPDF();
				    pdf.addImage(canvasOrDataUrl, 'PNG', 0, 0);
				    pdf.save(`uvi_form_${vehicle.id}.pdf`);
				} else {
					document.body.appendChild(canvasOrDataUrl);
				}
			})
			.catch((error) => {
				console.log('oops, something went wrong!', error);
			});
	}

	render() {
		const self = this;
		const vehicle = this.props.active_vehicle;
		const estimateFields = ['job description', 'part number', 'qty', 'parts', 'labor hours', 'labor', 'total'];
		const dealership = this.props.installation;
		const uvi_notes_colObject = _.find(vehicle.vehicle_properties, { key: 'uvi notes' }) || [];
		const uvi_status = _.find(vehicle.vehicle_properties, { key: 'uvi status' }) || [];
		let detail_type = '';
		let setdisabled = false;
		const detailvalue = this.vehicleProperty('uvi detail package');

		let button = <button className="btn btn-success hider" onClick={this.onLock}>Estimate Complete</button>;
		let add_estimates = <span>
			<button className="btn btn-success hider" onClick={self.onClickSublet}>
				<i className="fa fa-plus" />
				{' '}
Sublet Item
			</button>
		</span>;
		let add_items = <tr>
			<th className="" colSpan={7}>
				<button className="btn btn-success hider" onClick={self.onClick}>
					<i className="fa fa-plus" />
					{' '}
Pre-set Item
				</button>
				<button className="btn btn-success space-left-2 hider" onClick={self.onClickCustom}>
					<i className="fa fa-plus" />
					{' '}
Additional Work Item
				</button>
			</th>
		</tr>;
		let userstamp = '';

		if (uvi_status.value === 'completed') {
			setdisabled = true;
			userstamp = `Approved By ${uvi_status.user_name} - ${userStamp(uvi_status.updated_at)}`;
			button = <button className="btn btn-danger hider">Locked</button>;
			add_estimates = '';
			add_items = '';
			if (this.props.lookupIsAdmin()) {
				button = <button className="btn btn-danger" onClick={this.onUnLock}>UnLock</button>;
			}
		} else if (uvi_status.value === 'waiting') {
			setdisabled = false;
			button = <span>
				<button className="btn btn-success hider" onClick={this.onLock}>Approved</button>
				<button className="btn btn-danger hider" onClick={this.onDeclineAll}>Decline All</button>
			</span>;
		} else {
			setdisabled = false;
			button = <button className="btn btn-warning hider" onClick={this.onEstimateComplete}>Estimate Complete</button>;
		}

		if (this.state.detailvalue === 'UVI Full-Gas' || this.state.detailvalue === 'UVI Full-Diesel' || this.state.detailvalue === 'UVI Full-Hybrid' || this.state.detailvalue === 'CPO Inspection') {
			detail_type = <tr>
				<th>DETAIL FULL</th>
				<th />
				<th />
				<th />
				<th />
				<th className={`labor_${vehicle.id}`} data-total={200.00}>200.00</th>
				<th className={`total_${vehicle.id}`} data-total={200.00}>$200.00</th>
                 </tr>;
		}
		if (this.state.detailvalue === 'SSG - Safety, LOF, Air Filter, Rotate Tires') {
			detail_type = <tr>
				<th>DETAIL SSG</th>
				<th />
				<th />
				<th />
				<th />
				<th className={`labor_${vehicle.id}`} data-total={100}>$100.00</th>
				<th className={`total_${vehicle.id}`} data-total={100}>$100.00</th>
                 </tr>;
		}

		return (<div className="uvi-form container space-top-1">
			<button onClick={this.onPrint} className="btn btn-success space-left-2">Save to PDF</button>
			<div className="card" id={`uvi_form_${vehicle.id}`}>
				<div style={{ width: '800px' }}>
					<table className="table table-striped" cellPadding={1}>
					   <thead>
							<tr>
								<td colSpan={7}>
									<div className="text-capitalize text-center">
										{dealership.softname}
										{' '}
										<br />
										{dealership.street1}
										{' '}
										{dealership.city}
										{' '}
,
										{' '}
										{dealership.state}
										{' '}
-
										{' '}
										{dealership.zip}
									</div>

								</td>
							</tr>
							<tr>
								<td>
									<div className="text-capitalize text-center">
Stock:
										{vehicle.stock}
										{' '}
Vin:
										{vehicle.vin}
									</div>
								</td>
								<td>
									<div className="text-capitalize text-center">
Ro:
										{this.vehicleProperty('ro')}
									</div>
								</td>
								<td colSpan={5} rowSpan={2}>
Estimate Prepared By:
									{this.vehicleProperty('advisor')}
								</td>
							</tr>
							<tr>
								<td>
									<div className="text-capitalize text-center">
										{vehicle.year}
										{' '}
										{vehicle.make}
										{' '}
										{vehicle.model}
									</div>
								</td>
								<td>
									<div className="text-capitalize text-center">
Miles:
										{vehicle.miles}
									</div>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th className="text-center" style={{ width: '30%' }}>Job Description</th>
								<th className="text-center" style={{ width: '15%' }}>Part Number</th>
								<th className="text-center" style={{ width: '15%' }}>Qty</th>
								<th className="text-center" style={{ width: '10%' }}>Parts</th>
								<th className="text-center" style={{ width: '5%' }}>Labor Hours</th>
								<th className="text-center" style={{ width: '10%' }}>Labor</th>
								<th className="text-center" style={{ width: '15%' }}>Total</th>
							</tr>
							<tr>
								<td>
									<Select
										titleHelper={''}
										title={'Choose UVI Package'}
										onChange={this.onChangeSelect}
										value={detailvalue}
										name={'detailvalue'}
										disabled={setdisabled}
										list={this.detailOptions()}
										update_type={'uvi_form_vehicle'}
									/>
								</td>
								<td />
								<td>{this.state.detail_qty}</td>
								<td className={`parts_${vehicle.id}`} data-total={this.state.detail_parts}>{this.state.detail_parts}</td>
								<td className={`labor_hours_${vehicle.id}`} data-total={this.state.detail_labor_hours}>{this.state.detail_labor_hours}</td>
								<td className={`labor_${vehicle.id}`} data-total={this.state.detail_labor}>{this.state.detail_labor}</td>
								<td className={`total_${vehicle.id}`} data-total={this.state.detail_total}>{this.state.detail_total}</td>
							</tr>
							<tr>
								<td>UVI Kit Includes: </td>
								<td colSpan={6}>Inspection, BG Kit, LOF(gas or diesel), Air Filter,  Wiper Blades (Standard) , Rotate Wheels.   Diesel kit includes fuel filters.</td>
							</tr>
							<tr>
								<th>IKON GPS</th>
								<th />
								<th>1</th>
								<td className={`parts_${vehicle.id}`} data-total={83}>83.00</td>
								<td className={`labor_hours_${vehicle.id}`} data-total={0} />
								<td className={`labor_${vehicle.id}`} data-total={35}>35.00</td>
								<th className={`total_${vehicle.id}`} data-total={118}>$118.00</th>
							</tr>
							{detail_type}
							<tr>
								<th className="">STATE INSPECTION</th>
								<th />
								<th />
								<th />
								<th />
								<th className={`labor_${vehicle.id}`} data-total={Number(dealership.subdomain === 'pilot' ? '25.50' : '7.00')}>{dealership.subdomain === 'pilot' ? '$25.50' : '$7.00'}</th>
								<th className={`total_${vehicle.id}`} id={`si_${vehicle.id}`} data-total={Number(dealership.subdomain === 'pilot' ? '25.50' : '7.00')}>{dealership.subdomain === 'pilot' ? '$25.50' : '$7.00'}</th>
							</tr>
							{add_items}
							<tr className={this.state.tires_active ? 'text-center' : 'text-center strikeout'}>
								<th colSpan={4} className="">
									<div className="d-flex">
										<div className="p-2 flex-fill">
											<input
												onChange={this.onChange}
												onBlur={this.onBlur}
												type={'text'}
												name={'tires_part_number'}
												value={this.state.tires_part_number}
												className="form-control"
												placeholder="Tire Size"
												disabled={setdisabled}
											/>
										</div>
										<div className="p-2 flex-fill">
											<div className="input-group input-group-sm">
												<div className="input-group-prepend">
													<span className="input-group-text">Qty:</span>
												</div>
												<input
													onChange={this.onChangeTires}
													type={'number'}
													name={'tires_qty'}
													value={this.state.tires_qty}
													className="form-control"
													disabled={setdisabled}
												/>
											</div>
										</div>
										<div className="p-2 flex-fill">
											<div className={`parts_${vehicle.id} input-group input-group-sm`} data-total={this.state.tires_active ? this.state.tires_parts : 0}>
												<div className="input-group-prepend">
													<span className="input-group-text">Price:</span>
												</div>
												<input
													onChange={this.onChangeTires}
													type={'text'}
													name={'tires_parts'}
													value={this.state.tires_parts}
													className="form-control"
													placeholder="Price"
													disabled={setdisabled}
												/>
											</div>
										</div>
									</div>
								</th>
								<td className={`labor_hours_${vehicle.id}`} data-total={this.state.tires_active ? this.state.tires_labor_hours : 0}>{this.state.tires_labor_hours}</td>
								<td className={`labor_${vehicle.id}`} data-total={this.state.tires_active ? this.state.tires_labor : 0}>{this.state.tires_labor}</td>
								<th className={`total_${vehicle.id}`} data-total={this.state.tires_active ? this.state.tires_total : 0}>{this.state.tires_total}</th>
								<td>{this.state.tires_active && !setdisabled ? <button className="btn btn-sm btn-danger" onClick={self.onDecline}>Decline</button> : ''}</td>
								<td />
							</tr>
							<tr>
								<th className="text-center">Job Description</th>
								<th className="text-center">Part Number</th>
								<th className="text-center">Qty</th>
								<th className="text-center">Parts</th>
								<th className="text-center">Labor Hours</th>
								<th className="text-center">Labor</th>
								<th className="text-center">Total</th>
							</tr>
							{this.props.work_items.map((work_item) => {
								if (work_item.item_type === 'set-locked') {
									return <UviItem setdisabled={setdisabled} key={`superform_work_item_single_${work_item.id}`} {...this.props} work_item={work_item} vehicle={vehicle} />;
								}
							})}
							{this.props.work_items.map((work_item) => {
								if (work_item.item_type === 'custom') {
									return <UviCustomItem setdisabled={setdisabled} key={`custom_item_single_${work_item.id}`} {...this.props} work_item={work_item} vehicle={vehicle} />;
								}
							})}
							<tr>
								<th className="" colSpan={6}>{add_estimates}</th>
								<th>Estimate</th>
							</tr>
							{this.props.work_items.map((work_item) => {
								if (work_item.item_type === 'sublet') {
									return <SubletItem key={`superform_sublet_${work_item.id}`} {...this.props} work_item={work_item} vehicle={vehicle} setdisabled={setdisabled} />;
								}
							})}
							<tr>
								<th />
								<th />
								<th />
								<th colSpan={3}>
Sublet Total:
									<br />
									<small>Markup capped at $300</small>
								</th>
								<th id={`sublet_subtotal_${vehicle.id}`} />
							</tr>
							<tr>
								<th>Labor</th>
								<th className="text-right" id={`labor_total_${vehicle.id}`} />
								<th colSpan={5} rowSpan={7}>
									<h3>Notes:</h3>
				   <AllInputs
										value={uvi_notes_colObject.value || ''}
										type={'textarea-big'}
										name={'uvi notes'}
										parentid={vehicle.id}
										updatetype="vehicleproperty"
										user_name={uvi_notes_colObject.user_name}
										updated_at={uvi_notes_colObject.updated_at}
										vehicle={vehicle}
										setdisabled={setdisabled}
				   />
									<br />
									<input
										onChange={this.onChange}
										onBlur={this.onBlurTotal}
										type={'text'}
										name={'uvi_approved_total'}
										value={this.state.uvi_approved_total}
										className="form-control"
										placeholder="Approved Total"
										disabled={setdisabled}
									/>
									{button}
									{' '}
									{userstamp}
								</th>
							</tr>
							<tr>
								<th>Total Parts</th>
								<th className="text-right" id={`parts_total_${vehicle.id}`} />
							</tr>
							<tr>
								<th>Labor Hours</th>
								<th className="text-right" id={`labor_hours_total_${vehicle.id}`} />
							</tr>
							<tr>
								<th>Shop Supplies</th>
								<th className="text-right" id={`shop_supplies_total_${vehicle.id}`} />
							</tr>
							<tr>
								<th>Sublet Estimate</th>
								<th className="text-right" id={`sublet_total_${vehicle.id}`} />
							</tr>
							<tr className={this.state.decline_all ? 'strikeout' : ' '}>
								<th>Total Primary</th>
								<th className="text-right" id={`primary_total_${vehicle.id}`} />
							</tr>
							<tr className={this.state.decline_all ? 'strikeout' : ' '}>
								<th>Total Estimate</th>
								<th className="text-right" id={`other_total_${vehicle.id}`} />
							</tr>
						</tbody>
					</table>
				</div>

			</div>
			<div className="card-footer">
				<Estimates vehicle={vehicle} vehicle_id={vehicle.id} />
			</div>
		</div>
		);
	}
}

const mappedActions = {
	addWorkItem,
	addUviItem,
	addVehicleProperty,
	vsearch,
	processDepartments,
	addVehicleNoteRaw,
	lookupIsAdmin,
	addPartOrderRaw,
	addVehiclePropertyRaw,
};

function mapStateToProps(state) {
	return {
		all_tabs: state.settings_site.all_tabs,
		vehicle_columns: state.settings_departments.vehicle_columns,
		installation: state.settings_dealerships.installation,
		current_user: state.current_user,
		vehicle_filter: state.settings_nav.vehicle_filter,
		active_vehicle: state.settings_refresh.active_vehicle,
		work_items: state.settings_refresh.active_vehicle.work_items || [],
	};
}

export default connect(mapStateToProps, mappedActions)(UviForm);
