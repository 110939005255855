import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Charts from './ChartSingleWorkFlowPie';
import Table from './Table';
import TableWorkFlow from './TableWorkFlow';
import Dropdown from '../../inputs/Dropdown';
import DropdownMultiple from '../../inputs/DropdownMultiple';
import { readableStampSimple, getDayMonthYear } from '../../utils/dateFunctions';
import {
	setCurrentReportTab,
	syncReportData,
	setReportStartDate,
	setReportEndDate,
	setReportTimeRange,
} from '../../actions/apiSetInfo';
import {
	reportVehicles,
	filterReport,
	createReportData,
	runReport,
} from '../../actions/apiReports';
import {
	toggleReportBar,
	toggleReportNav,
	change_page,
} from '../../actions/toggleMenu';


const ButtonGroup = ({
	name, icon, onClick, active,
}) => (
	<button className={active ? 'btn btn-success active pad-top-1 pad-bottom-1 shadow_2' : 'btn btn-default pad-top-1 pad-bottom-1 shadow_2'} name={name} onClick={onClick}>
		<i className={`${icon} float-left`} />
		{name}
	</button>

);

class WorkFlowDataReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			report_start_date: this.props.report_start_date,
			report_end_date: this.props.report_end_date,
			report_dealerships: this.props.report_dealerships,
			report_departments: this.props.report_departments,
		};
		this.changeReportTab = this.changeReportTab.bind(this);
		this.onChange = this.onChange.bind(this);
		this.toggleReportBar = this.toggleReportBar.bind(this);
		this.selectCurrentReport = this.selectCurrentReport.bind(this);
		this.selectTimeRange = this.selectTimeRange.bind(this);
		this.updateReport = this.updateReport.bind(this);
	}

	dealerSettingValue(name) {
		const self = this;

		const value = _.sortBy(_.filter(self.props.dealer_settings, o => o.key === name), 'id');

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	changeReportTab(e) {
		this.props.setCurrentReportTab(e.target.name);
	}

	updateReport(e) {
		const self = this;
		Promise.all([
			self.props.change_page(''),
		]).then(() => self.props.change_page('reports'));
	}

	onChange(e) {
		const self = this;
		this.setState({
			[e.target.name]: e.target.value,
		});
		switch (e.target.name) {
		case 'report_start_date':
			self.props.setReportStartDate(e.target.value);
			break;
		case 'report_end_date':
			self.props.setReportEndDate(e.target.value);
			break;
		default:
		}
	}

	toggleReportBar() {
		this.props.toggleReportBar();
	}

	selectRelatedInstallations() {
		const dealerships = '';

		const selected_dealerships = this.props.report_dealerships.split(',');

		const options = this.props.related_installations.map((option) => {
			let selected = false;
			const dataid = 0;

			if (selected_dealerships.includes(option.subdomain)) {
				selected = true;
			}

			if (typeof option.softname !== 'undefined') {
				var obj = {
					id: option.id, label: option.softname, subdomain: option.subdomain, selected, dataid,
				};
			} else {
				var obj = {
					id: option.id, label: option.subdomain, subdomain: option.subdomain, selected, dataid,
				};
			}
			return obj;
		});
		return options;
	}

	selectCurrentReport() {
		const comparisonArray = [{ id: 1, name: 'Step Averages', label: 'Step Averages' },
			{ id: 3, name: 'WorkFlow Averages', label: 'WorkFlow Averages' }];

		const options = comparisonArray.map((option) => {
			let selected = false;
			const dataid = 0;
			if (this.props.current_report === option.name) {
				selected = true;
			}

			if (typeof option.softname !== 'undefined') {
				var obj = {
					id: option.id, label: option.label, selected,
				};
			} else {
				var obj = {
					id: option.id, label: option.label, selected,
				};
			}
			return obj;
		});
		return options;
	}

	selectTimeRange() {
		var d = new Date();
		var last = d.getMonth() - 1;
		var second = d.getMonth() - 2;
		var third = d.getMonth() - 3;
		var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

		if (last < 0) {
			last += 12;
		}
		if (second < 0) {
			second += 12;
		}
		if (third < 0) {
			third += 12;
		}
		var lastMonth = months[last];
		var secondMonth = months[second];
		var thirdMonth = months[third];

		const comparisonArray = [{
			id: 2, name: 'Month to Date', label: 'Month to Date', value: 'Month to Date',
		},
		{
			id: 3, name: 'last', label: lastMonth, value: lastMonth,
		},
		{
			id: 4, name: 'second', label: secondMonth, value: secondMonth,
		},
		{
			id: 5, name: 'third', label: thirdMonth, value: thirdMonth,
		},
		{
			id: 6, name: '90', label: 'Last 90 days', value: 'Last 90 days',
		},
		{
			id: 7, name: '60', label: 'Last 60 days', value: 'Last 60 days',
		},
		{
			id: 8, name: '30', label: 'Last 30 days', value: 'Last 30 days',
		},
		{
			id: 9, name: '14', label: 'Last 14 days', value: 'Last 14 days',
		},
		{
			id: 10, name: '7', label: 'Last 7 days', value: 'Last 7 days',
		}];

		const options = comparisonArray.map((option) => {
			let selected = false;
			const dataid = 0;
			if (this.props.report_time_range === option.name) {
				selected = true;
			}

			if (typeof option.name !== 'undefined') {
				var obj = {
					id: option.id, name: option.name, value: option.name, label: option.label, selected,
				};
			} else {
				var obj = {
					id: option.id, name: option.name, value: option.name, label: option.label, selected,
				};
			}
			return obj;
		});
		return options;
	}

	selectDepartments() {
		const departments = this.props.report_departments.split(',');
		const options = [];

		const original_departments = _.filter(this.props.departments, o => o.department_type === 'department');

		this.props.departments.map((option) => {
			let selected = false;

			if (departments.includes(option.id.toString())) {
				selected = true;
			}

			if (typeof option.name !== 'undefined') {
				var obj = { id: option.id, label: option.name, selected };
			} else {
				var obj = { id: option.id, label: option.name, selected };
			}
			if (option.department_type == 'department' || option.department_type == 'data step') {
				options.push(obj);
			}
		});
		return options;
	}

	selectFilters() {
		const report_filters = this.props.report_filters.split(',');
		const options = [];

		this.props.all_filters.map((option) => {
			let selected = false;

			if (report_filters.includes(option.id.toString())) {
				selected = true;
			}

			if (typeof option.name !== 'undefined') {
				var obj = { id: option.id, label: option.filter_name, selected };
			} else {
				var obj = { id: option.id, label: option.filter_name, selected };
			}
			options.push(obj);
		});
		return options;
	}

	selectWorkFlows() {
		const report_work_flows = this.props.report_work_flows.split(',');
		const options = [];

		this.props.work_flows.map((option) => {
			let selected = false;

			if (report_work_flows.includes(option.id.toString())) {
				selected = true;
			}

			if (typeof option.id !== 'undefined') {
				var obj = { id: option.id, label: option.name, selected };
			} else {
				var obj = { id: option.id, label: option.name, selected };
			}
			options.push(obj);
		});
		return options;
	}

	render() {
		const self = this;
		const report_average = '';
		const report_start_date = this.props.report_start_date;
    	const report_end_date = this.props.report_end_date;
    	let report_title = '';
    	const dropdowns = [];
    	const timerange = '';

			report_title = `${this.props.current_report} | ${readableStampSimple(report_start_date)} - ${readableStampSimple(report_end_date)}`;

    		const location = <li className="nav-item" key="location">
				<div className="col">
					<small>Location:</small>
					<br />
					<DropdownMultiple
						{...this.props}
						titleHelper="Location"
						title="Locations"
						list={this.selectRelatedInstallations()}
						update_type={'report_dealerships'}
						report_dealerships={this.props.report_dealerships}
					/>
				</div>
                       </li>;

			if (this.props.related_installations.length !== 1) {
				dropdowns.push(location);
			}

			dropdowns.push(<li className="nav-item" key="work_flows">
				<div className="col">
					<small>WorkFlows:</small>
					<br />
					<DropdownMultiple
						{...this.props}
						titleHelper="WorkFlow"
						title="WorkFlows"
						list={this.selectWorkFlows()}
						update_type={'report_work_flows'}
						report_work_flows={this.props.report_work_flows}
					/>
				</div>
                  </li>);

			dropdowns.push(<li className="nav-item" key="update_report">
				<div className="col">
					<small>Update Report:</small>
					<br />
					<button className="btn btn-success" onClick={this.updateReport}>Run Report</button>
				</div>
			</li>);

    	const report_average_title = '';
    	const report_average_box = '';

    	let current_report_tab = '';

		if (this.props.all_vehicles.length === 0) {
			current_report_tab = '';
		} else {
			current_report_tab = this.props.current_report;
		}
		var report_data = [];

		const report_dealerships = this.props.report_dealerships.split(',');
		const report_departments = this.props.report_departments.split(',');
		var report_type = 'step-30';

		report_type = `work_flow_${this.props.report_time_range}`;

		const report_work_flows = this.props.report_work_flows.split(',');

		report_data.yAxis = [];
		report_data.rawtime_24_5 = [];
		report_data.rawtime_24_6 = [];
		report_data.xCount = [];
		report_data.xAxis = [];
		report_data.dataId = [];
		report_data.departmentId = [];
		report_data.goal = [];
		var last_updated = 'never';
		const dealership_report_hours = this.dealerSettingValue('dealership_report_hours');

		if (report_type.includes('work_flow_')) {
			report_dealerships.map((dealership) => {
				report_data.xCount[dealership] = [];
				report_data.yAxis[dealership] = [];
				report_data.rawtime_24_5[dealership] = [];
				report_data.rawtime_24_6[dealership] = [];
				report_data.dataId[dealership] = [];
				report_data.departmentId[dealership] = [];
				report_data.goal[dealership] = [];
				this.props.report_department_data.map((option) => {
					if (dealership === option.dealership && report_type === option.report_type && report_work_flows.includes(option.work_flow_id.toString())) {
						report_data.xCount[dealership].push(option.vehicle_count || 0);
						report_data.xAxis.push(option.work_flow_id);
						const rawtime_24_7 = (option.rawtime_24_7 / 24).toFixed(2);
						const rawtime_24_6 = (option.rawtime_24_6 / 24).toFixed(2);
						const rawtime_24_5 = (option.rawtime_24_5 / 24).toFixed(2);
						report_data.rawtime_24_5[dealership].push(Number(rawtime_24_5));
						report_data.rawtime_24_6[dealership].push(Number(rawtime_24_6));

						if (dealership_report_hours === 'age-24_7') {
							report_data.yAxis[dealership].push(Number(rawtime_24_7));
						} else if (dealership_report_hours === 'age-24_6') {
							report_data.yAxis[dealership].push(Number(rawtime_24_6));
						} else {
							report_data.yAxis[dealership].push(Number(rawtime_24_5));
						}

						report_data.dataId[dealership].push(option.id);
						report_data.departmentId[dealership].push(option.work_flow_id);
						report_data.goal[dealership].push(option.goal);
					}
				});
			});
		}

		const n = Math.random();

    	return (
			<div className="col-md-12 reportbox bg-light mobile-pad-left-2">
				<div className="navbar navbar-expand-lg navbar-light bg-white space-top-1 space-bottom-1">
				  <button className="navbar-toggler" type="button" onClick={this.props.toggleReportNav} aria-expanded="false" aria-label="Toggle navigation">
				    <span className="navbar-toggler-icon" />
				  </button>

				  <div className={`${this.props.report_menu_status ? 'row' : 'collapse row '} navbar-collapse mobile-pad-left-2`}>
				    <ul className="navbar-nav mr-auto">
							<li className="nav-item" key="work_flows">
								<div className="col">
									<small>Time Range:</small>
									<br />
									<Dropdown
										{...this.props}
										titleHelper="Time Range"
										title="Range"
										list={this.selectTimeRange()}
										update_type={'report_time_range'}
										value={this.props.report_time_range}
									/>
								</div>
							</li>
							{this.props.report_time_range === 'Custom' ? timerange : ''}
							{dropdowns}
				    </ul>
				  </div>
				</div>

				<div className="container-fluid bg-light space-top-1">

				   <div className="row">
					   		<div className="col-md-5 pad-left-1">
					   			{this.props.report_department_data.length > 0 ? <Charts key={report_title} report_title={report_title} data={report_data} container="chart" /> : <div className="row text-center bg-dark"><img src={'https://cdn.ipacketrecon.com/images/loading.gif'} /></div>}
						</div>
						<div className="col-md-7 bg-light">
							{this.props.report_department_data.length > 0 ? (this.props.current_report === 'WorkFlow Averages' ? <TableWorkFlow data={report_data} dealership_report_hours={dealership_report_hours} /> : <Table data={report_data} dealership_report_hours={dealership_report_hours} />) : ''}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	setCurrentReportTab,
	syncReportData,
	setReportStartDate,
	reportVehicles,
	setReportEndDate,
	setReportTimeRange,
	filterReport,
	toggleReportBar,
	toggleReportNav,
	runReport,
	createReportData,
	change_page,
};

function mapStateToProps(state) {
	return {
		searched_vehicles: state.settings_refresh.searched_vehicles,
		current_report_tab: state.settings_reports.current_report_tab,
		departments: state.settings_departments.departments,
		related_installations: state.settings_dealerships.related_installations,
		report_start_date: state.settings_reports.report_start_date,
		report_end_date: state.settings_reports.report_end_date,
		report_dealerships: state.settings_reports.report_dealerships,
		report_departments: state.settings_reports.report_departments,
		report_data_filtered: state.settings_reports.report_data_filtered,
		report_monthly_data_filtered: state.settings_reports.report_monthly_data_filtered,
		current_report: state.settings_reports.current_report,
		work_flows: state.settings_departments.work_flows,
		all_filters: state.settings_site.all_filters || [],
		report_work_flows: state.settings_reports.report_work_flows,
		report_filters: state.settings_reports.report_filters,
		report_bar: state.settings_nav.menus.report_bar,
		report_menu_status: state.settings_nav.menus.report_menu_status,
		report_time_range: state.settings_reports.report_time_range,
		all_vehicles: state.settings_refresh.all_vehicles,
		report_department_data: state.settings_reports.report_department_data,
		installation: state.settings_dealerships.installation,
		current_user: state.current_user,
		dealer_settings: state.settings_site.dealer_settings,
	};
}

export default connect(mapStateToProps, mappedActions)(WorkFlowDataReport);