import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import { SwatchesPicker } from 'react-color';
import DropdownMultiple from './DropdownMultiple';
import Dropdown from './Dropdown';
import NewDropdown from './NewDropdown';

import {
	addDealerSetting,
} from '../actions/apiDealerUpdates';

class EditDealershipSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.value || '', checked: this.props.value || '', background_color: this.props.background_color, text_color: this.props.text_color,
		};
		this.onChange = this.onChange.bind(this);
		this.onChangeChecked = this.onChangeChecked.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.handleChangeComplete = this.handleChangeComplete.bind(this);
		this.onEditClick = this.onEditClick.bind(this);
		this.onChangeSelectDealerSetting = this.onChangeSelectDealerSetting.bind(this);
	}

	onChange(e) {
		this.setState({ value: e.target.value });
	}

	onEditClick() {
		this.props.setEditTab(this.props.name);
		this.props.change_page('tab-settings-edit');
	}

	handleChangeComplete(e) {
		this.setState({ value: e.hex });

		const self = this;
		const errorType = 'editColorPicker';
		const data = {
			key: self.props.name,
			value: e.hex,
		};

		self.props.addDealerSetting(data, errorType);
	}

	onChangeInput(e) {
		const self = this;
		const errorType = 'editDealerSetting';
		const data = {
			key: self.props.name,
			value: e.target.value,
		};

		self.setState({ value: e.target.value });
		self.props.addDealerSetting(data, errorType);
	}

	onBlur(e) {
		if (e.target.value !== this.props.value) {
			const self = this;
			const errorType = 'editDealerSetting';
			const data = {
				key: self.props.name,
				value: e.target.value,
			};

			self.setState({ value: e.target.value });
			self.props.addDealerSetting(data, errorType);
		}
	}

	onChangeSelect(e) {
		const self = this;
		const errorType = 'editDealerSetting';
		const data = {
			key: self.props.name,
			value: e.value,
		};

		self.setState({ value: e.value });
		self.props.addDealerSetting(data, errorType);
	}

	onChangeSelectDealerSetting(e) {
		const self = this;
		const errorType = 'editDealerSetting';
		const data = {
			key: e.name,
			value: e.value,
		};

		self.setState({ value: e.value });
		self.props.addDealerSetting(data, errorType);
	}

	onChangeChecked(e) {
		const self = this;
		const errorType = 'editDealerSetting';
		const value = e.target.checked ? 'true' : '';

		const data = {
			key: self.props.name,
			value,
		};

		self.setState({ checked: e.target.checked });

		self.props.addDealerSetting(data, errorType);
	}

	selectOptions() {
		const departments = this.props.options.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { id: option.id, value: option.name, label: option.name };
			} else {
				var obj = { id: option.id, value: option, label: option };
			}
			return obj;
		});
		return departments;
	}

	selectDashboardTimes() {
		const times = ['7day', '14day', '30day', 'Current'];

		let id = 1;
		const options = times.map((option) => {
			let selected = false;
			if (option == this.props.value) {
				selected = true;
			}
			if (typeof option !== 'undefined') {
				var obj = {
 id, value: option, name: option, label: option, selected,
};
			} else {
				var obj = {
 id, value: option, name: option, label: option, selected,
};
			}
			id += 1;

			return obj;
		});
		return options;
	}

	selectTime() {
		const times = ['12:00am', '12:15am', '12:30am', '12:45am', '1:00am', '1:15am', '1:30am', '1:45am', '2:00am', '2:15am', '2:30am', '2:45am', '3:00am', '3:15am', '3:30am', '3:45am', '4:00am', '4:15am', '4:30am', '4:45am', '5:00am', '5:15am', '5:30am', '5:45am', '6:00am', '6:15am', '6:30am', '6:45am', '7:00am', '7:15am', '7:30am', '7:45am', '8:00am', '8:15am', '8:30am', '8:45am', '9:00am', '9:15am', '9:30am', '9:45am', '10:00am', '10:15am', '10:30am', '10:45am', '11:00am', '11:15am', '11:30am', '11:45am', '12:00pm', '12:15pm', '12:30pm', '12:45pm', '1:00pm', '1:15pm', '1:30pm', '1:45pm', '2:00pm', '2:15pm', '2:30pm', '2:45pm', '3:00pm', '3:15pm', '3:30pm', '3:45pm', '4:00pm', '4:15pm', '4:30pm', '4:45pm', '5:00pm', '5:15pm', '5:30pm', '5:45pm', '6:00pm', '6:15pm', '6:30pm', '6:45pm', '7:00pm', '7:15pm', '7:30pm', '7:45pm', '8:00pm', '8:15pm', '8:30pm', '8:45pm', '9:00pm', '9:15pm', '9:30pm', '9:45pm', '10:00pm', '10:15pm', '10:30pm', '10:45pm', '11:00pm', '11:15pm', '11:30pm', '11:45pm'];

		let id = 1;
		const options = times.map((option) => {
			let selected = false;
			if (option.label == this.props.value) {
				selected = true;
			}
			if (typeof option !== 'undefined') {
				var obj = {
 id, value: option, label: option, selected,
};
			} else {
				var obj = {
 id, value: option, label: option, selected,
};
			}
			id += 1;

			return obj;
		});
		return options;
	}

	selectData() {
		let id = 1;
		const options = this.props.vehicle_columns.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { id, value: option.name, label: option.name };
			} else {
				var obj = { id, value: option.name, label: option.name };
			}
			id += 1;

			return obj;
		});
		return options;
	}

	selectDepartments(keyname) {
		const options = this.props.departments.map((option) => {
			let selected = false;
			if (option.id == this.props.value) {
				selected = true;
			}

			if (typeof option.name !== 'undefined') {
				var obj = {
 id: option.id, value: option.id, name: keyname, label: option.name, selected,
};
			} else {
				var obj = {
 id: option.id, value: option.id, name: keyname, label: option.name, selected,
};
			}
			return obj;
		});
		return options;
	}

	selectColumns() {
		const options = this.props.vehicle_columns.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { id: option.id, value: option.name, label: option.name };
			} else {
				var obj = { id: option.id, value: option.name, label: option.name };
			}
			return obj;
		});
		return options;
	}

	selectTimeZones() {
		const comparisonArray = [{ id: 1, name: 'Pacific Time (US & Canada)', label: 'Pacific Time (US & Canada)' },
			{ id: 2, name: 'Mountain Time (US & Canada)', label: 'Mountain Time (US & Canada)' },
			{ id: 3, name: 'Central Time (US & Canada)', label: 'Central Time (US & Canada)' },
			{ id: 4, name: 'Eastern Time (US & Canada)', label: 'Eastern Time (US & Canada)' },
			{ id: 5, name: 'Atlantic Time (Canada)', label: 'Atlantic Time (Canada)' },
			{ id: 5, name: 'Adelaide', label: 'Australia/Adelaide' }];

		const options = comparisonArray.map((option) => {
			let selected = false;
			if (option.label == this.props.value) {
				selected = true;
			}
			if (typeof option.name !== 'undefined') {
				var obj = {
					id: option.id, name: option.label, value: option.label, label: option.label, selected,
				};
			} else {
				var obj = {
					id: option.id, name: option.label, value: option.label, label: option.label, selected,
				};
			}
			return obj;
		});
		return options;
	}

	selectReportHours() {
		const comparisonArray = [{ id: 1, name: '24_7', label: '24/7 All Day - All Week' },
			{ id: 2, name: '24_6', label: '24/6 All Day no Sunday' },
			{ id: 3, name: '24_5', label: '24/5 All Day no Weekends' },
			{ id: 4, name: 'dealership_hours', label: 'Use Dealership Working Hours' },
			{ id: 5, name: 'department_hours', label: 'Use department hours (defaults to dealership)' }];

		const options = comparisonArray.map((option) => {
			let selected = false;
			if (option.name == this.props.value) {
				selected = true;
			}
			if (typeof option.name !== 'undefined') {
				var obj = {
 id: option.id, value: option.label, label: option.label, selected,
};
			} else {
				var obj = {
 id: option.id, value: option.label, label: option.label, selected,
};
			}
			return obj;
		});
		return options;
	}

	selectTimeTabs() {
		const comparisonArray = [{ id: 1, name: 'age-24_7', label: 'Age-24/7 All Day - All Week' },
			{ id: 2, name: 'age-24_6', label: 'Age-24/6 All Day no Sunday' },
			{ id: 3, name: 'age-24_5', label: 'Age-24/5 All Day no Weekends' },
			{ id: 4, name: 'age-recononly-24_7', label: 'Age-ReconOnly-24/7 All Day - All Week' },
			{ id: 5, name: 'age-recononly-24_6', label: 'Age-ReconOnly-24/6 All Day no Sunday' },
			{ id: 6, name: 'age-recononly-24_5', label: 'Age-ReconOnly-24/5 All Day no Weekends' },
			{ id: 7, name: 'department-24_7', label: 'Department-24/7 All Day - All Week' },
			{ id: 8, name: 'department-24_6', label: 'Department-24/6 All Day no Sunday' },
			{ id: 9, name: 'department-24_5', label: 'Department-24/5 All Day no Weekends' },
			{ id: 10, name: 'department-instance-24_7', label: 'Department-Single-Instance-24/7 All Day - All Week' },
			{ id: 11, name: 'department-instance-24_6', label: 'Department-Single-Instance-24/6 All Day no Sunday' },
			{ id: 12, name: 'department-instance-24_5', label: 'Department-Single-Instance-24/5 All Day no Weekends' },
			{ id: 13, name: 'none', label: 'None' }];

		const options = comparisonArray.map((option) => {
			let selected = false;
			if (option.name == this.props.value) {
				selected = true;
			}
			if (typeof option.name !== 'undefined') {
				var obj = {
					id: option.id, name: option.name, value: option.label, label: option.label, selected,
				};
			} else {
				var obj = {
					id: option.id, name: option.name, value: option.label, label: option.label, selected,
				};
			}
			return obj;
		});
		return options;
	}

	selectReconWorkFlow() {
		const options = this.props.work_flows.map((option) => {
			let selected = false;
			if (option.name == this.props.value) {
				selected = true;
			}
			if (typeof option.name !== 'undefined') {
				var obj = {
					id: option.id, name: option.name, value: option.name, label: option.name, selected,
				};
			} else {
				var obj = {
					id: option.id, name: option.name, value: option.name, label: option.name, selected,
				};
			}
			return obj;
		});
		return options;
	}

	returnDescription() {
		switch (this.props.name) {
		case 'wholesale_car_default_department':
			return 'Vehicles Marked as Wholsale through classification/vehicletype/stock sequence...';

		case 'transit_car_default_department':
			return 'In Transit Vehicles';

		case 'prep_car_default_department':
			return 'Prep Stage';

		case 'new_car_default_department':
			return 'New Car Default Department';

		case 'used_car_default_department':
			return 'Used Car Default Department';

		case 'external_car_default_department':
			return 'Vehicles from another dealership location';

		case 'timedstart1_car_default_department':
			return 'Vehicle will be moved from this stage';

		case 'timedinto1_car_default_department':
			return 'Into this stage';

		case 'timedstart1_days':
			return 'After this many days *more options coming soon';

		case 'timedstart2_car_default_department':
			return 'Vehicle will be moved from this stage';

		case 'timedinto2_car_default_department':
			return 'Into this stage';

		case 'timedstart2_days':
			return 'After this many days';

		case 'RA_workflow_key':
			return 'Select the vehicle column to create a rule';

		case 'recall_api':
			return 'Recall api with parts availability - (API by Recall Masters)';

		case 'text_messaging':
			return 'All text message notifications - (Outside US charges may apply)';

		case 'tab_history':
			return 'Vehicle History and Recon Summary';

		case 'tab_file_attachment':
			return 'File upload to be attached to vehicle. Allowed FileTypes: pdf,jpeg,jpg,png,gif,doc,xls';

		case 'tab_history_detailed':
			return 'Timeline of vehicle history with time entered and completed each step as well as the user who moved the vehicle. This also includes all data that has been changed since the start of the vehicle in Recon Advisor';

		case 'tab_parts_order':
			return 'Parts Order Form. Tab will turn orange when there are parts waiting. If expected date has past - tab goes red. Once parts are marked as being on hand, the tab will turn green.';

		case 'tab_estimate':
			return 'Estimates with email or text message notifications for approvals';

		case 'tab_pictures':
			return 'Pictures count from feed. Slideshow of pictures available as well.';

		case 'tab_cost':
			return 'Include a tab showing the cost of the vehicle. (Used with Salesman Profiles)';

		case 'tab_carfax':
			return 'CarFax Accounts can be linked for quick access to the CarFax';

		case 'tab_invoice':
			return 'General Invoice Form';

		case 'tab_body_work':
			return 'Body Work Diagram used to indicate work needed or required. Tab goes orange when work is needed, red when required, and green when completed. ';

		case 'tab_inspection':
			return 'Inpsection form to indicate Needed/Required/Completed. Form can be used with data queues for quick reference.';

		case 'tab_todo':
			return 'Simple todo list to keep track of items needed. We can preload this with typical items to save time and data entry';

		case 'tab_appraisal':
			return 'Appraisal information - usually pulled in from companies like vAuto. Ask your Recon Advisor to get set up.';

		case 'tab_loaner_form':
			return 'Handy Loaner Form to track loaner vehicles';

		case 'tab_checkboxes':
			return 'Checkboxes used in the tabs for quick acess and reference. Orange typcically means needed. Red overdue or required, and Green as Completed.';

		case 'tab_customer_pay':
			return 'Customer Pay Form.';

		case 'tab_appointment':
			return 'Set appointments on vehicles';

		case 'tab_deposit':
			return 'Deposit Info.';

		default:
			return '';
		}
	}

	render() {
		return (
			<div className="col-md-12">
				<div className="card departmentCard">
					<div className="card-body row">
						<div className="col-md-12">
							<div className={classnames('form-group', { 'has-danger': this.props.error })}>
								{(() => {
									switch (this.props.type) {
									case 'switch':
										return <span className="switch">
											<input
												onChange={this.onChangeChecked}
												type={'checkbox'}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`ds_${this.props.parentid}_${this.props.name}`}
												checked={this.props.value == 'true' ? 'checked' : ''}
												className={classnames('text-center', { 'form-control-danger': this.props.error })}
												id={`switch_id_${this.props.name}`}
											/>
											<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.name}`}>{this.props.label.replace(/_/g, ' ')}</label>
										</span>;

									case 'switch-edit':
										return <div>
											<span className="switch">
												<input
													onChange={this.onChangeChecked}
													type={'checkbox'}
													name={this.props.name}
													data-id={this.props.parentid}
													key={`ds_${this.props.parentid}_${this.props.name}`}
													checked={this.props.value == 'true' ? 'checked' : ''}
													className={classnames('text-center', { 'form-control-danger': this.props.error })}
													id={`switch_id_${this.props.name}`}
												/>
												<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.name}`}>{this.props.label.replace(/_/g, ' ')}</label>
											</span>
											{this.props.value == 'true' && this.props.extras == 'true' ? <button className={'btn btn-success btn-sm float-right'} onClick={this.onEditClick}>
												<i className="fa fa-edit" />
												{' '}
Settings
											</button> : ''}
										</div>;

									case 'select':
										return <div className="form-group">
											<label className="control-label">{this.props.label}</label>
											<Select
												onChange={this.onChangeSelect}
												value={this.selectOptions().filter(({ value }) => value === this.state.value)}
												name={this.props.name}
												options={this.selectOptions()}
												className="text-capitalize text-dark"
												data-id={this.props.parentid}
												clearable={false}
												searchable={false}
												key={`ds_${this.props.parentid}_${this.props.name}`}
												menuContainerStyle={{ zIndex: 5 }}
											/>
										</div>;

									case 'select-data':
										return <div className="form-group bg-dark text-white">
											<label className="control-label">{this.props.label}</label>
											<Select
												onChange={this.onChangeSelect}
												value={this.selectData().filter(({ value }) => value === this.state.value)}
												name={this.props.name}
												options={this.selectData()}
												className="text-capitalize text-dark"
												data-id={this.props.parentid}
												clearable={false}
												searchable={false}
												key={`ds_${this.props.parentid}_${this.props.name}`}
												menuContainerStyle={{ zIndex: 5 }}
											/>
										</div>;

									case 'select-data-no-label':
										return <Select
											onChange={this.onChangeSelect}
											value={this.selectData().filter(({ value }) => value === this.state.value)}
											name={this.props.name}
											options={this.selectData()}
											className="text-capitalize text-dark"
											data-id={this.props.parentid}
											clearable={false}
											searchable={false}
											key={`ds_${this.props.parentid}_${this.props.name}`}
											menuContainerStyle={{ zIndex: 5 }}
										/>;

									case 'timepicker':

										return <div className="col-md-12">
											<label className="form-control btn-light pad-right-1 pad-left-1">{this.props.label}</label>
											<Dropdown
												{...this.props}
												titleHelper="Timezone"
												title={'Select'}
												name={this.props.name}
												list={this.selectTime()}
												update_type={'dealership_setting'}
												user_id={this.props.user_id}
												department_id={this.props.parentid}
												value={this.props.value}
											/>
										</div>;

									case 'select-timezone':

										return <div className="col-md-12">
											<label className="form-control btn-light pad-right-1 pad-left-1">Set Dealership Timezone</label>
											<Dropdown
												{...this.props}
												titleHelper="Departments"
												title={'Select Timezone'}
												name={'dealership_time_zone'}
												list={this.selectTimeZones()}
												update_type={'dealership_setting'}
												user_id={this.props.user_id}
												department_id={this.props.parentid}
												value={this.props.value}
											/>
										</div>;

									case 'select-dashboard-report':

										return <div className="col-md-12">
											<label className="form-control btn-light pad-right-1 pad-left-1">{this.props.label}</label>
											<Dropdown
												{...this.props}
												titleHelper="Report Work Flow"
												title={'Select'}
												name={this.props.name}
												list={this.selectReconWorkFlow()}
												update_type={'dealership_setting'}
												user_id={this.props.user_id}
												department_id={this.props.parentid}
												value={this.props.value}
											/>
										</div>;

									case 'select-dashboard-time':

										return <div className="col-md-12">
											<label className="form-control btn-light pad-right-1 pad-left-1">{this.props.label}</label>
											<Dropdown
												{...this.props}
												titleHelper="Report Time Type"
												title={'Select Dashboard Time Type'}
												name={this.props.name}
												list={this.selectDashboardTimes()}
												update_type={'dealership_setting'}
												user_id={this.props.user_id}
												department_id={this.props.parentid}
												value={this.props.value}
											/>
										</div>;

									case 'select-departments':

										return <div className="col-md-12">
											<label className="form-control btn-light pad-right-1 pad-left-1">{this.props.label}</label>
											<NewDropdown
												{...this.props}
												titleHelper="Timezone"
												title={'Select'}
												name={this.props.name}
												list={this.selectDepartments(this.props.name)}
												update_type={'dealership_setting_department'}
												user_id={this.props.user_id}
												department_id={this.props.parentid}
												value={parseInt(this.props.value)}
												onChange={this.onChangeSelectDealerSetting}
											/>
										</div>;

									case 'select-report-hours':

										return <div className="col-md-12">
											<label className="form-control btn-light pad-right-1 pad-left-1">{this.props.label}</label>
											<Dropdown
												{...this.props}
												titleHelper="Dealer Report Hours"
												title={'Select'}
												name={this.props.name}
												list={this.selectReportHours()}
												update_type={'dealership_setting_report_hours'}
												user_id={this.props.user_id}
												department_id={this.props.parentid}
												value={this.props.value}
											/>
										</div>;

									case 'select-time-tabs':

										return <div className="col-md-12">
											<label className="form-control btn-light pad-right-1 pad-left-1">{this.props.label}</label>
											<Dropdown
												{...this.props}
												titleHelper="Time Tabs"
												title={'Select'}
												name={this.props.name}
												list={this.selectTimeTabs()}
												update_type={'dealership_setting_time_tabs'}
												user_id={this.props.user_id}
												department_id={this.props.parentid}
												value={this.props.value}
											/>
										</div>;

									case 'select-recon-workflow':

										return <div className="col-md-12">
											<label className="form-control btn-light pad-right-1 pad-left-1">{this.props.label}</label>
											<Dropdown
												{...this.props}
												titleHelper="Time Tabs"
												title={'Select'}
												name={this.props.name}
												list={this.selectReconWorkFlow()}
												update_type={'dealership_setting_workflows'}
												user_id={this.props.user_id}
												department_id={this.props.parentid}
												value={this.props.value}
											/>
										</div>;

									case 'select-columns':
										return <div className="form-group">
											<label className="control-label">{this.props.label}</label>
											<Select
												onChange={this.onChangeSelect}
												value={this.selectColumns().filter(({ value }) => value === this.state.value)}
												name={this.props.name}
												options={this.selectColumns()}
												className="text-capitalize text-dark"
												clearable={false}
												searchable={false}
												key={`ds_${this.props.parentid}_${this.props.name}`}
												menuContainerStyle={{ zIndex: 5 }}
											/>
										</div>;

									case 'text':
										return <div className="form-group">
											<label className="control-label">{this.props.label}</label>
											<input
												onChange={this.onChange}
												onBlur={this.onBlur}
												value={this.state.value}
												type={this.props.type}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`ds_${this.props.parentid}_${this.props.name}`}
												className={classnames('form-control ', { 'form-control-danger': this.props.error })}
											/>
										</div>;

									case 'number':
										return <div className="form-group">
											<label className="control-label">{this.props.label}</label>
											<input
												onChange={this.onChange}
												onBlur={this.onBlur}
												value={this.state.value}
												type={this.props.type}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`ds_${this.props.parentid}_${this.props.name}`}
												className={classnames('form-control ', { 'form-control-danger': this.props.error })}
											/>
										</div>;

									case 'colorpicker':
										return <div className="form-group">
											<label className="control-label">{this.props.label}</label>
											<SwatchesPicker
												color={this.state.value}
												onChangeComplete={this.handleChangeComplete}
												type={this.props.type}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`ds_${this.props.parentid}_${this.props.name}`}
											/>
										</div>;

									default:
										return <input
											onChange={this.onChange}
											value={this.state.value}
											type={this.props.type}
											name={this.props.field}
											className={classnames('form-control', { 'form-control-danger': this.props.error })}
										/>;
									}
								})()}

							</div>
							{this.returnDescription() !== '' ? <div className="col-md-12">
								<small>
									<i className={'fa fa-info-circle text-info'} />
									{' '}
									{this.returnDescription()}
								</small>
							</div> : ''}
						</div>

					</div>

				</div>
			</div>
		);
	}
}

const mappedActions = {
	addDealerSetting,
};

function mapStateToProps(state) {
	return {
		vehicle_columns: state.settings_departments.vehicle_columns,
		departments: state.settings_departments.original_departments,
		work_flows: state.settings_departments.work_flows,
	};
}
export default connect(mapStateToProps, mappedActions)(EditDealershipSettings);
