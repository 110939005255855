import React, { Component, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { MaterialReactTable } from 'material-react-table';
import Select from 'react-select';
import {
 Box, Button, createTheme, ThemeProvider, useTheme, MenuItem, ListItemIcon,
} from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import AllInputs from '../../../../inputs/AllInputs';
import {
 getDateTime, readableStamp,
} from '../../../../utils/dateFunctions';
import TextFieldGroup from '../../../../inputs/TextFieldGroup';
import {
	appraisalRequestNotification,
	appraisalNotification,
} from '../../../../actions/apiNotifications';
import {
	lookupUserById,
	lookupDepartmentById,
	lookupDepartmentByName,
	lookupVehicleEstimateById,
	vsearch,
  lookupRecipients,
} from '../../../../actions/lookups';

import {
	addEstimate, sendEstimate, deleteEstimate,
} from '../../../../actions/apiEstimates';

const NewEstimates = (props) => {
  const tableRef = useRef();
  const csvExporter = new ExportToCsv(csvOptions);
  const columns = [
    {
      accessorKey: 'recipients',
      header: 'recipients',
      size: 250,
      enableEditing: false,
      Cell: ({ renderedCellValue, row }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Select
              titleHelper={'Type'}
              title={'Choose Recipients'}
              name={'recipients'}
              value={selectRecipients().filter(({ value }) => value == row.original.recipients[0])}
              options={selectRecipients()}
              clearable={false}
              searchable
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              className="text-capitalize"
              isDisabled={row.original.ro_status[0] === 'getapproved' ? false : row.original.lock_estimate[0]}
              vehicle={props.active_vehicle.vehicle_id}
              onChange={onChangeRecipients(row.original.estimate_id[0])}
              styles={{
                container: (base) => ({
                  ...base,
                  width: '100%',
                }),
              }}
          />
        </Box>
      ),
    },
    {
      accessorKey: 'description',
      header: 'description',
      size: 250,
      enableEditing: true,
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        disabled: row.original.lock_estimate[0],
        onBlur: (event) => {
          const data = {
            vehicle_id: props.active_vehicle.id,
            description: event.target.value,
          };
          props.sendEstimate(row.original.estimate_id[0], data, 'addEstimateError');
        },
      }),
      Cell: ({ renderedCellValue, row }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          {row.original.estimate_type[0] == 'blank_parts_and_labor' ? renderedCellValue : <Select
              titleHelper={'Type'}
              title={'Choose'}
              name={'WorkItem'}
              value={selectWorkItem().filter(({ value }) => value === row.original.description[0])}
              options={selectWorkItem()}
              clearable={false}
              searchable
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              className="text-capitalize"
              isDisabled={row.original.lock_estimate[0]}
              vehicle={props.active_vehicle.vehicle_id}
              onChange={onChangeWorkItem(row.original.estimate_id[0])}
              styles={{
                container: (base) => ({
                  ...base,
                  width: '100%',
                }),
              }}
          />}
        </Box>
      ),
    },
    {
      accessorKey: 'category',
      header: 'classification',
      enableEditing: false,
    },
    {
      accessorKey: 'parts_price',
      header: 'Parts',
      enableEditing: true,
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        disabled: row.original.ro_status[0] === 'getapproved' ? !row.original.lock_item_1 : row.original.lock_estimate[0],
        onBlur: (event) => {
          var estimate_total = Number(event.target.value) + Number(row.original.labor_price[0]);
          const data = {
            vehicle_id: props.active_vehicle.id,
            parts_price: event.target.value,
            estimate_total,
            estimate: estimate_total,
          };
          props.sendEstimate(row.original.estimate_id[0], data, 'addEstimateError');
        },
      }),
    },
    {
      accessorKey: 'labor_units',
      header: 'Labor Units',
      enableEditing: true,
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        disabled: row.original.ro_status[0] === 'getapproved' ? !row.original.lock_item_2 : row.original.lock_estimate[0],
        onBlur: (event) => {
          var labor_price = Number(event.target.value) * 95.55;
          var estimate_total = Number(row.original.parts_price[0]) + Number(labor_price);

          const data = {
            vehicle_id: props.active_vehicle.id,
            labor_units: event.target.value,
            estimate_total,
            labor_price,
          };
          props.sendEstimate(row.original.estimate_id[0], data, 'addEstimateError');
        },
      }),
    },
    {
      accessorKey: 'labor_price',
      header: 'Labor',
      enableEditing: true,
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        disabled: row.original.ro_status[0] === 'getapproved' ? !row.original.lock_item_3 : row.original.lock_estimate[0],
        onBlur: (event) => {
          var estimate_total = Number(event.target.value) + Number(row.original.parts_price[0]);
          const data = {
            vehicle_id: props.active_vehicle.id,
            labor_price: event.target.value,
            estimate_total,
            estimate: estimate_total,
          };
          props.sendEstimate(row.original.estimate_id[0], data, 'addEstimateError');
        },
      }),
    },
    {
      accessorKey: 'estimate_total',
      header: 'Total',
      enableEditing: true,
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        disabled: row.original.ro_status[0] === 'getapproved' ? !row.original.lock_item_4 : row.original.lock_estimate[0],
        onBlur: (event) => {
          var estimate_total = Number(event.target.value);
          const data = {
            vehicle_id: props.active_vehicle.id,
            estimate_total,
          };
          props.sendEstimate(row.original.estimate_id[0], data, 'addEstimateError');
        },
      }),
    },
    {
      accessorKey: 'ro_status',
      enableEditing: false,
      header: 'Status',
      size: 250,
      Cell: ({ renderedCellValue, row }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          {(() => {
							switch (row.original.ro_status[0]) {
							case 'pending':
								return (
									<div className="p-2">
										<p className="text-center">
											<small className="text-capitalize" key={`requested_sign_${row.original.estimate_id[0]}`}>
											Requested By:
												{' '}
												{row.original.requested_by_uid[0] ? props.lookupUserById(row.original.requested_by_uid[0]).user_name : ''}
												<br />
												{row.original.requested_date[0] ? readableStamp(row.original.requested_date[0]) : ''}
											</small>
										</p>
										<div className={'btn-group'} role="group">
											<button className="btn btn-sm btn-success" onClick={onApprove(row.original)}>Approve</button>
											<button className="btn btn-sm btn-danger" onClick={onDeny(row.original)}>Deny</button>
										</div>
									</div>
								);

							case 'approved':
								return (
									<button className="btn btn-sm btn-success text-capitalize">
									Approved
										{' '}
										{row.original.approved_by_uid[0] ? props.lookupUserById(row.original.approved_by_uid[0]).user_name : ''}
										{row.original.approved_date[0] ? readableStamp(row.original.approved_date[0]) : ''}
									</button>
								);

							case 'declined':
								return <div className="p-2">
									<div className="p-2">
										<button className="btn btn-sm btn-danger">
										Denied
											{' '}
											{row.original.declined_by_uid[0] ? props.lookupUserById(row.original.declined_by_uid[0]).user_name : ''}
											{row.original.declined_date[0] ? readableStamp(row.original.declined_date[0]) : ''}
										</button>
									</div>
									<div className="p-2">Denied Reason:</div>
									<div className="p-2"><AllInputs
								value={row.original.declined_reason[0] || ''}
								type={'textarea'}
								name={'declined_reason'}
								parentid={props.active_vehicle.id}
								estimate_id={row.original.estimate_id[0]}
								updatetype="estimate"
								user_name={''}
								updated_at={''}
								vehicle={props.active_vehicle}
									/></div>
									<div className="p-2">New Amount: <AllInputs
								value={row.original.counter_estimate[0] || ''}
								type={'dollar'}
								name={'counter_estimate'}
								parentid={props.active_vehicle.id}
								estimate_id={row.original.estimate_id[0]}
								updatetype="estimate"
								user_name={''}
								updated_at={''}
								vehicle={props.active_vehicle}
									/></div>
								</div>;

							default:
								return row.original.recipients[0].length > 0 ? <button className="btn btn-sm btn-success" onClick={onSend(row.original)}>Get Approved</button> : '(Select Recipient)';
							}
						})()}
        </Box>
      ),
    },
    {
      accessorKey: 'lock_estimate',
      header: 'Delete',
      enableEditing: false,
      Cell: ({ renderedCellValue, row }) => (
        <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
        >{row.original.ro_status[0] === 'getapproved' || row.original.ro_status[0] === 'pending' ? (
          <button className="btn btn-sm btn-danger" data-id={row.original.estimate_id[0]} onClick={onDelete}>X</button>
      ) : ''}
        </Box>
      ),
    },
  ];
	const newvehicle = {
    vehicle_id: props.active_vehicle.id,
    ro_status: 'getapproved',
  };
	const onDelete = (e) => {
    console.log(e);
		props.deleteEstimate(e.target.dataset.id, props.active_vehicle.id, 'addDeleteEstimateError');
	};
  const onClick = (e) => {
		props.addEstimate(newvehicle, 'addDeleteEstimateError');
	};

  const selectRecipients = () => {
    const users = props.lookupRecipients();

    const options = users.map((option) => {
          var obj = {
            id: option.id, label: option.user_name, value: option.id,
          };
          return obj;
        });
        return options || [];
      };

  const onApprove = (estimate) => (e) => {
		const subdomain = props.installation.database;
		const message = 'Estimate Approved';
		const data = {
			requested_by_uid: estimate.requested_by_uid[0],
			ro_status: 'approved',
			approved_by_uid: props.current_user.user,
			approved_date: getDateTime(),
			vehicle_id: props.active_vehicle.id,
		};

        const data2 = {
            note_type: 'appraisal_approved',
            message,
            user_id: parseInt(estimate.requested_by_uid[0]),
            data_id: estimate.estimate_id[0],
            vehicle_id: props.active_vehicle.id,
        };
        props.appraisalNotification(data2, 'approvalRequestNotification', subdomain);

		props.sendEstimate(estimate.estimate_id[0], data, 'addEstimateError');
	};

	const onDeny = (estimate) => (e) => {
		const subdomain = props.installation.database;
		const message = 'Estimate Declined';
		const data = {
			vehicle_id: props.active_vehicle.id,
			ro_status: 'declined',
			declined_by_uid: props.current_user.user,
			declined_date: getDateTime(),
		};
        const data2 = {
            note_type: 'appraisal_declined',
            message,
            user_id: parseInt(estimate.requested_by_uid[0]),
            data_id: estimate.estimate_id[0],
            vehicle_id: props.active_vehicle.id,
        };
        props.appraisalNotification(data2, 'approvalRequestNotification', subdomain);
		props.sendEstimate(estimate.estimate_id[0], data, 'addEstimateError');
	};

  const onSend = (estimate) => (e) => {
    console.log(estimate);
		const data = {
			vehicle_id: props.active_vehicle.id,
			requested_by_uid: props.current_user.user,
			requested_date: getDateTime(),
			ro_status: 'pending',
			lock_estimate: 'true',
		};
		props.sendEstimate(estimate.estimate_id[0], data, 'addEstimateError');

		const message = `Vehicle Vin:${props.active_vehicle.vin} Stock: ${props.active_vehicle.stock} requires your approval. Vehicle is currently located in the ${props.lookupDepartmentById(props.active_vehicle.department_id).name} stage`;
		const recipients = estimate.recipients;

		if (parseInt(estimate.recipients) > 0) {
      const data2 = {
        note_type: 'appraisal_request',
        message,
        user_id: parseInt(estimate.recipients[0]),
        data_id: estimate.estimate_id[0],
        vehicle_id: props.active_vehicle.id,
      };
      props.appraisalRequestNotification(data2, 'approvalRequestNotification');
    }
	};

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const selectWorkItem = () => {
    const options = _.sortBy(props.work_item_presets, 'name').map((option) => {
          var obj = {
            id: option.id,
            label: option.name,
            value: option.name,
            parts_price: option.parts_price,
            labor_units: option.labor_units,
            labor_price: option.labor_price,
            category: option.category,
            estimate_total: option.estimate_total,
            estimate: option.estimate_total,
            estimate_type: option.estimate_type,
            parts_price_editable: option.parts_price_editable,
            labor_price_editable: option.labor_price_editable,
            labor_units_editable: option.labor_units_editable,
            estimate_total_editable: option.estimate_total_editable,
          };
          return obj;
        });
        return options || [];
      };

  const onChangeWorkItem = (estimate_id) => (e) => {
    console.log(estimate_id);
		const errorType = 'Change WorkItem';
        console.log(e);
        var lock = e.value !== 'Blank Row';
        var data = {
            estimate_total: e.estimate_total,
            labor_price: e.labor_price,
            labor_units: e.labor_units,
            estimate_type: e.estimate_type,
            category: e.category,
            estimate: e.estimate,
            vehicle_id: props.active_vehicle.id,
            description: e.value,
            lock_estimate: lock,
            lock_item_1: e.parts_price_editable,
            lock_item_2: e.labor_units_editable,
            lock_item_3: e.labor_price_editable,
            lock_item_4: e.estimate_total_editable,

        };
		props.sendEstimate(estimate_id, data, 'addEstimateError');
	};

  const onChangeRecipients = (estimate_id) => (e) => {
    console.log(estimate_id);
		const errorType = 'Change WorkItem';
        console.log(e.id);

        var data = {
            recipients: e.id,
            vehicle_id: props.active_vehicle.id,
        };
		props.sendEstimate(estimate_id, data, 'addEstimateError');
	};

	const globalTheme = useTheme();

	  const tableTheme = useMemo(
	    () => createTheme({
	        palette: {
	          mode: 'light', // let's use the same dark/light mode as the global theme
	        },
	      }),
	    [globalTheme],
	  );

	  const handleExportRows = (rows) => {
	    csvExporter.generateCsv(rows.map((row) => row.original));
	  };

	  const handleExportData = () => {
	    csvExporter.generateCsv(data);
	  };

  	const newdata = [];
    var estimates_total = 0;

    _.orderBy(props.active_vehicle.appraisals, 'id', 'desc').map(estimate => {
		if (estimate.ro_status !== 'expense') {
              estimates_total += Number(estimate.estimate_total);

              newdata.push({
                estimate: [estimate.estimate],
                ro_number: [estimate.ro_number],
                parts_price: [estimate.parts_price],
                labor_price: [estimate.labor_price],
                labor_units: [estimate.labor_units],
                item_5_type: [estimate.item_5_type],
                description: [estimate.description],
                estimate_total: [estimate.estimate_total],
                ro_status: [estimate.ro_status],
                action: [estimate.ro_status],
                classification: [estimate.classification],
                category: [estimate.category],
                estimate_type: [estimate.estimate_type],
                estimate_id: [estimate.id],
                requested_by_uid: [estimate.requested_by_uid],
                approved_by_uid: [estimate.approved_by_uid],
                approved_date: [estimate.approved_date],
                declined_by_uid: [estimate.declined_by_uid],
                declined_date: [estimate.declined_date],
                declined_reason: [estimate.declined_reason],
                lock_estimate: [estimate.lock_estimate],
                recipients: [estimate.recipients],
                requested_date: [estimate.requested_date],
                counter_estimate: [estimate.counter_estimate],
                lock_item_1: estimate.lock_item_1,
                lock_item_2: estimate.lock_item_2,
                lock_item_3: estimate.lock_item_3,
                lock_item_4: estimate.lock_item_4,
            });
			}
          });
    console.log('appraisals',props.active_vehicle.appraisals)
		return (
			<ThemeProvider theme={tableTheme}>
      <div className="container-fluid pad-left-2 pad-right-2">
    <MaterialReactTable
      tableRef={tableRef}
      columns={columns}
      data={newdata}
      pageSize={20}
      enableEditing
      editDisplayMode={'cell'}
      createDisplayMode={'custom'}
      renderTopToolbarCustomActions={({ table }) => (
        <Box><Button
          variant="contained"
          onClick={() => {
            table.setCreatingRow(true);
            onClick();
          }}
        >
            Create New Work Item
          </Button>
          <Button className="pull-right" style={{ margin: 20 }}>Total: ${estimates_total.toFixed(2)}</Button></Box>
      )}
      renderDetailPanel={({ row }) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        />
      )}
    /></div></ThemeProvider>
		);
};

const mappedActions = {
	addEstimate,
  sendEstimate,
  appraisalRequestNotification,
	appraisalNotification,
  lookupUserById,
  lookupRecipients,
  deleteEstimate,
	lookupDepartmentById,
	lookupDepartmentByName,
	lookupVehicleEstimateById,
	vsearch,
};

function mapStateToProps(state) {
	return {
		active_vehicle: state.settings_refresh.active_vehicle,
		appraisals: state.settings_refresh.appraisals,
		installation: state.settings_dealerships.installation,
		all_tabs: state.settings_site.all_tabs,
    work_item_presets: state.settings_refresh.work_item_presets,
	};
}

export default connect(mapStateToProps, mappedActions)(NewEstimates);
